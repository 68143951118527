import React, { useState, useEffect} from "react";
import { ref, uploadBytesResumable, getDownloadURL} from "firebase/storage";
import { storage, db, doc, getDoc } from "./firebaseConfig";
import axios from "axios";
import "./JobApplication.css";
import Navbar from "./Components/Navbar/Navbar";
import Footer from "./Components/Footer/Footer";
import { useParams } from 'react-router-dom';

const JobApplication = () => {
  const { id } = useParams(); // Grab the id from the URL
  const [jobDetails, setJobDetails] = useState(null);

  // Fetch job details from Firestore based on the job id
  useEffect(() => {
    const fetchJobDetails = async () => {
      try {
        const jobRef = doc(db, 'jobs', id); // Reference to the job document with the id
        const jobSnap = await getDoc(jobRef);
        
        if (jobSnap.exists()) {
          setJobDetails(jobSnap.data());
        } else {
          console.log('No such job!');
        }
      } catch (error) {
        console.error('Error fetching job details:', error);
      }
    };

    fetchJobDetails();
  }, [id]); // Run effect whenever the id changes

  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    resume: null,
  });

  const [message, setMessage] = useState("");
  const [isUploading, setIsUploading] = useState(false);

  const handleChange = (e) => {
    const { name, value, files } = e.target;
    if (name === "resume") {
      setFormData({ ...formData, resume: files[0] });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!formData.resume) {
      setMessage("Please upload a resume.");
      return;
    }

    setIsUploading(true);
    try {
      const storageRef = ref(storage, `resumes/${formData.resume.name}`);
      const uploadTask = uploadBytesResumable(storageRef, formData.resume);

      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          console.log("Upload is " + progress + "% done");
        },
        (error) => {
          console.error("Upload error:", error);
          setMessage("Failed to upload resume.");
        },
        async () => {
          try {
            const resumeURL = await getDownloadURL(uploadTask.snapshot.ref);
            const response = await axios.post("https://sendemailtest-3uxxq7kkra-uc.a.run.app", {
              firstName: formData.firstName,
              lastName: formData.lastName,
              email: formData.email,
              phone: formData.phone,
              resumeURL,
              jobTitle: jobDetails.jobTitle,
            });

            if (response.status === 200) {
              setMessage("Application submitted successfully!");
            } else {
              setMessage("Failed to send email.");
            }
          } catch (error) {
            console.error("Error during email sending:", error);
            setMessage("Failed to send application.");
          }
        }
      );
    } catch (error) {
      console.error("Error during file upload:", error);
      setMessage("Failed to submit application.");
    } finally {
      setIsUploading(false);
    }
  };
  
  if (!jobDetails) {
    return <div>Loading job details...</div>;
  }

  return (
    <div>
      <Navbar></Navbar>
      <div className="jobapp__container">
        <div className="job__header">
          <h1 className="job__title" >{jobDetails.jobTitle}</h1>
          <h3 className="job__subheader">Job Description</h3>
          <p className="job__desc">{jobDetails.jobDesc}</p>

          <h3 className="job__subheader">Responsibilities</h3>
          <ul>
            {jobDetails.responsibilities?.map((resp, index) => (
              <li key={index}>{resp}</li>
            ))}
          </ul>

          <h3 className="job__subheader">Requirements</h3>
          <ul>
            {jobDetails.requirements?.map((req, index) => (
              <li key={index}>{req}</li>
            ))}
          </ul>

          <h3 className="job__subheader">Preferred Skills</h3>
          <ul>
            {jobDetails.preferredSkills?.map((skill, index) => (
              <li key={index}>{skill}</li>
            ))}
          </ul>

          <p className="job__desc">Please take a minute to apply if you feel your experiences align with the above description, we would love to have you join our dynamic team!</p>

        </div>
        {message ? 
        (
          <p className="job__message-response">{message}</p>
        ):
        (
          <form className="job__form" onSubmit={handleSubmit}>
            <h2>Application</h2>

            <div className="job__form-wrapper">
              <input
                type="text"
                name="firstName"
                placeholder="First Name"
                className="job__textinput"
                value={formData.firstName}
                onChange={handleChange}
                required
              />
              <input
                type="text"
                name="lastName"
                placeholder="Last Name"
                className="job__textinput"
                value={formData.lastName}
                onChange={handleChange}
                required
              />
            </div>
            <div className="job__form-wrapper">
              <input
                type="text"
                name="email"
                placeholder="Email"
                className="job__textinput"
                value={formData.email}
                onChange={handleChange}
                required
              />
              <input
                type="text"
                name="phone"
                placeholder="Phone #"
                className="job__textinput"
                value={formData.phone}
                onChange={handleChange}
                required
              />
            </div>
            <div className="job__form-resume-wrapper">
            <label htmlFor="resume" className="file-label">Upload your resume:</label>
              <input type="file" name="resume" onChange={handleChange} required className="job__textinput" />
            </div>
            <button type="submit" className="job__button" disabled={isUploading}>
              {isUploading ? "Uploading..." : "Submit"}
            </button>
          </form>
        )}
        
      </div>
      <Footer></Footer>
    </div>
  );
};

export default JobApplication;
