import React, { useState } from 'react';
import './Navbar.css';
import logo from "../../Images/logo.png"
import { useNavigate } from 'react-router-dom';
import { useTheme } from '../../ThemeContext';
import { Sun,Moon } from 'react-feather';
const Navbar = () => {
  const navigate = useNavigate()
  const { theme, toggleTheme } = useTheme();
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <nav className="navbar">
      <div className="nav-container">
        <p onClick={() => navigate(`/home`)} className="nav-logo" id="navbar-logo"><img alt="" src={logo} /></p>
        <div className={`nav-links ${isOpen ? 'open' : ''}`}>
          <p style={{ cursor: 'pointer' }} onClick={() => navigate(`/transportation`)} >TRANSPORTATION</p>
          <p style={{ cursor: 'pointer' }} onClick={() => navigate(`/logistics`)}>LOGISTICS</p>
          <p style={{ cursor: 'pointer' }} onClick={() => navigate(`/careers`)}>CAREERS</p>
          <p style={{ cursor: 'pointer' }} onClick={() => navigate(`/contact`)}>CONTACT US</p>
          {theme === 'light' ? <Moon style={{ cursor: 'pointer', color: 'var(--text-color)'}} onClick={toggleTheme} className="mode-icon-desktop"/> : <Sun style={{ cursor: 'pointer', color: 'var(--text-color)'}} className="mode-icon-desktop" onClick={toggleTheme}/>}
        </div>
        <div className="nav-mobile-container">
          {theme === 'light' ? <Moon style={{ cursor: 'pointer', color: 'var(--text-color)'}} onClick={toggleTheme} className="mode-icon-mobile"/> : <Sun style={{ cursor: 'pointer', color: 'var(--text-color)'}} className="mode-icon-mobile" onClick={toggleTheme}/>}
          <div className="hamburger" onClick={toggleMenu}>
            <span className="bar"></span>
            <span className="bar"></span>
            <span className="bar"></span>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
