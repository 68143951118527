import './Contact.css';
import Navbar from './Components/Navbar/Navbar'
import React, { useRef, useState } from 'react';
import emailjs from '@emailjs/browser';
import Footer from './Components/Footer/Footer';
const Contact = () => {
    const form = useRef();
    const [showModal, setShowModal] = useState(false);

    const sendEmail = (e) => {
      e.preventDefault();
      emailjs
        .sendForm('service_e48jbsj', 'template_rnhvdte', form.current, {
          publicKey: process.env.REACT_APP_EMAILJS_API_KEY,
        })
        .then(
          
          (result) => {
            console.log('SUCCESS!', result.text);
            setShowModal(true);
          },
          (error) => {
            console.log('FAILED...', error.text);
          },
        );
    };

    return (
      <div className="Contact">
        <Navbar /> 
        <div className="contact__container">
          <div className="contact__stripe-1"></div>
          <div className="contact__stripe-2"></div>
          <div className="contact__stripe-3"></div>

          <div className="contact__information">
            <div className="contact__header">
              <h1 className="contact__title">GET IN TOUCH</h1>
              <p className="contact__subtitle">See how we can help you fulfill your logistical needs</p>
            </div>
            <div className="contact__info">
              <p className="contact__details">
                <strong>Phone:</strong> 647-553-1293
              </p>
              <p className="contact__details">
                <strong>Address:</strong>  65 Huxley Rd, North York ON, M9M 3B4
              </p>

              <iframe
                className="contact__map"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d5766.784941566839!2d-79.52884855291734!3d43.72317312334238!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x882b30f0e907bd03%3A0xd0328e9f5d923d0!2s65%20Huxley%20Rd%2C%20North%20York%2C%20ON%20M9M%201H5!5e0!3m2!1sen!2sca!4v1733203401134!5m2!1sen!2sca"
                width="600"
                height="450"
                allowFullScreen=""
                loading="lazy"
                title="map"
              ></iframe>
            </div>
          </div>
          {!showModal ? (
            <div className="contact__form-container">
              <form ref={form} onSubmit={sendEmail} className="contact__form">
                <h2 className='contact__form-header'>SEND A MESSAGE</h2>
                <div className = "contact__form-wrapper">
                  <input type="text" name="form_name" class="contact__form-input" placeholder="Your Name"/>
                  <input type="text" name="form_phone" class="contact__form-input" placeholder="Phone #"/>
                </div>
                <div className = "contact__form-wrapper2">
                  <input type="text" name="form_email" className="contact__form-input" placeholder="Email"/>
                  <textarea name="message" className="contact__form-textarea" placeholder="Message"></textarea>
                </div>
                <button type="submit" value="Send" className="contact__form-button">Submit</button>
              </form>
            </div>
            ) : (
              <div className="contact__message-sent">
                Thanks for Submitting! We will get back to you soon.
              </div>
            )
            }
        </div> 
    
        <Footer/>
        
      </div>
    );
}

export default Contact;
