import './Logistics.css';
import './Home.css';
import Navbar from './Components/Navbar/Navbar'
import Logistic1 from "./Images/AI-Logistics-1.png"
import Carousel from './Components/Carousel/Carousel';
import CrossDocking from "./Images/Crossdocking.jpg";
import NewPallet from "./Images/NewPallet.JPG";
import HeatPallet from "./Images/HeatTreatedPallet.JPG";
import GradeAPallet from "./Images/GradeAPallet.JPG";
import GradeBPallet from "./Images/GradeBPallet.JPG";
import Footer from './Components/Footer/Footer';
import { useNavigate } from 'react-router-dom';
import { ChevronsDown } from 'react-feather';
const slides = [
  {
    title: "New Pallets",
    image: NewPallet,
    caption: "Industry stanard 48 x 40 Pallets, using 1 x 4 x 40 boards with 2 x 4 x 48 stringers",
  },
  {
    title: "Heat Treated Pallets",
    image: HeatPallet,
    caption: "Heat Treated to sterlize wood, complying with IPPC and ISPM-15 Regulations",
  },
  {
    title: "Grade A",
    image: GradeAPallet,
    caption: "Recycled Pallets, with very little to no damage",
  },
  {
    title: "Grade B (Mix Pallets)",
    image: GradeBPallet,
    caption: "The most economical choice for clients, popular in industry",
  },
];

const Logistics = () => {
  const navigate = useNavigate();
    return (
      <div className="Logistics">
        <Navbar />
        <div className="logistics__container">
          <div className="transportation__hero">
            <div class="transportation__hero-container">
              <div className="transportation__hero-text-container">
                <h1 className="transportation__hero-text-title">Logistics</h1>
                <div className="transportation__backdrop"></div>
                <h1 className="transportation__hero-text-para">Warehousing And Pallet Services For Your Business</h1>
                <h1 className="transportation__info-section">
                Our warehousing services in the GTA are designed to provide seamless logistics support for your business. With multiple strategically located facilities, we offer efficient cross-docking, custom and standard pallet creation, and inventory management solutions.  From small-scale inventory to high-volume distribution, we ensure your products are handled with care and expertise, helping your business thrive in a competitive market.
                </h1>
            </div>
            <div className="transportation__services_header">
              <h1 className="transportation__services_text">
                View Our Services
              </h1>
              <ChevronsDown className="transportation__services_chevron"></ChevronsDown>
            </div>
            </div>
          </div>

          <div className="logistics__pallet-section">
            <h1 className='logistics__pallet-header'>Pallets</h1>
            <p className='logistics__pallet-text'>View our assortment of pallets, with low minimum orders, we can produce what you are looking for in any combination of boards and stringers.</p>
            <Carousel slides={slides} />
          </div>

          <div className="logistics__info-section">
              <div className="logistics__info-card-container">
                  <div className="logistics__info-card" style={{
                  backgroundImage: `linear-gradient(
                    rgba(0, 0, 0, 0.4), 
                    rgba(0, 0, 0, 0.4)
                  ), url(${Logistic1})`,
                  backgroundSize: 'cover',
                  backgroundPosition: 'center',
                }}>
                      <div className="logistics__card-content">
                          <h3>Inventory</h3>
                          <p className="logistics__card-description">
                          We offer robust inventory management solutions, ensuring accurate tracking, secure storage, and seamless accessibility of your goods. Trust us to optimize your inventory for smooth operations and reduced costs.                        </p>
                      </div>
                  </div>
      
                  <div className="logistics__info-card" style={{
                  backgroundImage: `linear-gradient(
                    rgba(0, 0, 0, 0.4), 
                    rgba(0, 0, 0, 0.4)
                  ), url(${CrossDocking})`,
                  backgroundSize: 'cover',
                  backgroundPosition: 'center',
                }}>
                      <div className="logistics__card-content">
                          <h3>Cross-Docking</h3>
                          <p className="logistics__card-description">
                            Our cross-docking services enable swift transfer of goods from inbound to outbound transport, minimizing storage time and accelerating delivery. Count on us for faster, more efficient supply chain solutions.
                          </p>
                      </div>
                  </div>
              </div>
          </div>
          
          <div class="contact-section">
            <div class="contact-text-container">
              <h2 class="contact-title">We'd Love to Connect!</h2>
              <p class="contact-para">Get in touch to discover how our team can provide you with solutions to your logistics and transportation needs</p>
            </div>
            <div class="contact-button">
              <button onClick={() => navigate(`/contact`)} class="contactus-button">Contact Us</button>
            </div>
          </div>
        </div>
        <Footer/>
      </div>
    );
}

export default Logistics;
