import './Home.css';
import Navbar from './Components/Navbar/Navbar';
import Truck1 from "./Images/AI-Truck-2.png";
import Logistic1 from "./Images/AI-Logistics-1.png";
import Career1 from "./Images/truck-career.jpg";
import Career2 from "./Images/logistics-career.jpg";
import Footer from './Components/Footer/Footer';
import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import {ChevronRight, Search} from 'react-feather';

const Home = () => {
  const navigate = useNavigate();
  const [width, setWidth] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.scrollY;
      const docHeight = document.documentElement.scrollHeight - window.innerHeight;

      const midPoint = docHeight / 4;

      let scrollPercent;
      if (scrollTop <= midPoint) {
        scrollPercent = (scrollTop / midPoint) * 40;
      } else {
        scrollPercent = 40;
      }

      setWidth(scrollPercent);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
    return (
      <div className="Home">
        <Navbar />  


        <div className="home-container">
          <div className="home__hero">
              <div className="home__hero-text-container">
                <h1 className="home__hero-text-title">HARBOUR PALLET</h1>
                <h1 className="home__hero-text-para">Leader in Transportation and Logistics</h1>
                <div className="home__hero-contact-text-para">Discover how our expert team can deliver personalized logistics strategies and top-tier transportation solutions to you</div>
                <button onClick={() => navigate(`/contact`)} className="home__hero-square-button">Request a quote</button>
              </div>
              <div className= "home__hero-image-container"> </div>
          </div>

        
        <div className="home__info-section">
            <h1 className="home__info-title">Our Services</h1>
            <div className="home__info-title-underline" style={{ width: `${width}%` }}></div>            
                <div className="home__info-card-container">


                <div className="home__info-card"
                style={{
                  backgroundImage: `linear-gradient(
                    rgba(0, 0, 0, 0.4), 
                    rgba(0, 0, 0, 0.4)
                  ), url(${Truck1})`,
                  backgroundSize: 'cover',
                  backgroundPosition: 'center',
                }}
                >
                    <div className="home__card-content">
                        <h3>Transportation</h3>
                        <p className="home__card-description">
                            We provide service throughout North America, ensuring reliable, timely delivery of all goods—from temperature-sensitive items to bulk freight. Partner with us for seamless transportation solutions.
                        </p>
                        <button onClick={() => navigate(`/transportation`)} className="home__info-button">
                          Learn More<ChevronRight className="home__info-chevron-right" color = 'white' ></ChevronRight>
                        </button>
                    </div>

                </div>
    
                <div
                  className="home__info-card"
                  style={{
                    backgroundImage: `linear-gradient(
                      rgba(0, 0, 0, 0.4), 
                      rgba(0, 0, 0, 0.4)
                    ), url(${Logistic1})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                  }}
                >
                  <div className="home__card-content">
                    <h3>Logistics</h3>
                    <p className="home__card-description">
                      We provide comprehensive logistics services in the Greater Toronto Area, including warehousing and cross-docking, ensuring efficient and timely handling of all goods. Partner with us for reliable solutions that streamline your supply chain.
                    </p>
                    <button onClick={() => navigate(`/logistics`)} className="home__info-button">                         
                     Learn More<ChevronRight className="chevron-right" color = 'white' ></ChevronRight>
                    </button>
                  </div>
                </div>

            </div>
        </div>
  
      <div class="home__careers-section">
        <div class="home__careers-content">
          <div className="home__careers-image-container">
            <img src= {Career1} alt="Caring for Our People" className="home__careers-image-1" />
            <img src= {Career2} alt="Join Our Team" className="home__careers-image-2" />
          </div>
          <div className="home__careers-text-container">
            <h2 >Careers</h2>
            <p>We care about our people. Check out the roles we have available!</p>
            <button onClick={() => navigate(`/careers`)} class="home__careers-square-button">Explore Careers
              <Search className="home__careers-search-icon"></Search>
            </button>
          </div>
        </div>
      </div>
  

      </div>
      <Footer/>
    
    </div>
    );
}

export default Home;
