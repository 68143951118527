import React from 'react';
import logo from "../../Images/logo.png"
// import {Facebook, Instagram, Linkedin} from "react-feather"
import './Footer.css';

const Footer = () => {
  return (
    <div class="footer"> 
      <div class ="footer-left">
        <img class="footer-logo" id="navbar-logo" src={logo} alt="Company Logo" />
        <div class="footer-text-container">
            <p><strong>Phone:</strong> 647-553-1293</p>
            <p><strong>Email:</strong> info@harbourpallet.com</p>
        </div>
      </div>
      {/* <div class="footer-social">
          <Facebook  href="https://facebook.com" class="social-icon"></Facebook>
          <Instagram  href="https://instagram.com" class="social-icon"></Instagram>
          <Linkedin  href="https://linkedin.com" class="social-icon"></Linkedin>
      </div> */}
    </div>
  );
};

export default Footer;
