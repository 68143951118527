import './Transportation.css';
import Navbar from './Components/Navbar/Navbar'
import Truck1 from "./Images/AI-Truck-6.png"
import Truck2 from "./Images/AI-Truck-5.png"
import Truck3 from "./Images/AI-Truck-7.png"
import Footer from './Components/Footer/Footer';
import { useNavigate } from 'react-router-dom';
import { ChevronsDown } from 'react-feather';
const sections = [
  {
    title: "LTL",
    description: "We ensure the last mile of your product’s journey is handled with care and precision – arriving at its final destination on time, every time.",
    buttonText: "Learn more",
    align: "left",
    backgroundImage: Truck1
  },
  {
    title: "FTL",
    description: "Our nationwide network guarantees that your products reach every corner of the country with speed and reliability.",
    buttonText: "Discover more",
    align: "right",
    backgroundImage:  Truck2
  },
  {
    title: "Dry Van & Refrigerated",
    description: "Explore the variety of equipment our transportation team can offer to meet your needs",
    buttonText: "Explore options",
    align: "left",
    backgroundImage: Truck3
  },
];

const Transportation = () => {
  const navigate = useNavigate();
    return (
      <div className="Transportation">
        <Navbar />
        <div className="transportation__container">
          <div className="transportation__hero">
            <div class="transportation__hero-container">
              <div className="transportation__hero-text-container">
                <h1 className="transportation__hero-text-title">Transportation</h1>
                <div className="transportation__backdrop"></div>
                <h1 className="transportation__hero-text-para">Delivering Reliable and Efficient Solutions for Your Business</h1>
                <h1 className="transportation__info-section">
                    At the heart of our operations is a deep commitment to providing dependable transportation services across North America. Whether it’s dry or temperature-sensitive cargo, we understand that the safe, timely delivery of goods is essential to the success of your business. Our team of logistics professionals and skilled drivers is dedicated to ensuring that your shipments arrive on time, every time – with no compromises on reliability or efficiency.
                </h1>
            </div>
            <div className="transportation__services_header">
              <h1 className="transportation__services_text">
                View Our Services
              </h1>
              <ChevronsDown className="transportation__services_chevron"></ChevronsDown>
            </div>
            </div>
          </div>

          <div className="transportation__section-stack">
            {sections.map((section, index) => (
            <div
            key={index}
            className={`transportation__section ${section.align === 'left' ? 'left-align' : 'right-align'}`}
            style={{
              backgroundImage: `linear-gradient(to ${section.align === 'left' ? 'left' : 'right'}, rgba(0, 0, 0, 0), rgba(0, 0, 0, 1)), url(${section.backgroundImage})`,
            }} 
          >
            <div className="transportation__section-content">
              <h2 className="transportation__section-content-title">{section.title}</h2>
              <p className="transportation__section-content-text">{section.description}</p>
            </div>
          </div>
            ))}
          </div>
      
          <div class="contact-section">
            <div class="contact-text-container">
              <h2 class="contact-title">We'd Love to Connect!</h2>
              <p class="contact-para">Get in touch to discover how our team can provide you with solutions to your logistics and transportation needs</p>
            </div>
            <div class="contact-button">
              <button onClick={() => navigate(`/contact`)} class="contactus-button">Contact Us</button>
            </div>
          </div>
        </div>
        <Footer/>

      </div>
    );
}

export default Transportation;
