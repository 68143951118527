import React, { useState, useEffect } from "react";
import PropTypes from "prop-types"; // For type-checking props
import "./Carousel.css";

const Carousel = ({ slides }) => {
  const [currentSlide, setCurrentSlide] = useState(0);

  const nextSlide = () => {
    setCurrentSlide((prev) => (prev + 1) % slides.length);
  };

  const prevSlide = () => {
    setCurrentSlide((prev) => (prev - 1 + slides.length) % slides.length);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      nextSlide();
    }, 5000); // Auto-scroll every 5 seconds

    return () => clearInterval(interval); // Cleanup interval on unmount
  }, [currentSlide, nextSlide]);

  return (
    <div className="carousel">
      <button className="carousel-control prev" onClick={prevSlide}>
        &#10094;
      </button>

      <div className="carousel-content">
          <div className="carousel-card">
            <h3 className="carousel-card-title">{slides[(currentSlide - 1 + slides.length) % slides.length].title}</h3>
            <img
              src={slides[(currentSlide - 1 + slides.length) % slides.length].image}
              alt={slides[(currentSlide - 1 + slides.length) % slides.length].title}
              className="carousel-image"
            />
            <p className="carousel-card-caption">{slides[currentSlide].caption}</p>
          </div>

        {/* Main Slide */}
          <div className="carousel-card">
            <h3 className="carousel-card-title">{slides[currentSlide].title}</h3>
            <img
              src={slides[currentSlide].image}
              alt={slides[currentSlide].title}
              className="carousel-image"
            />
            <p className="carousel-card-caption">{slides[currentSlide].caption}</p>
          </div>

          <div className="carousel-card">
            <h3 className="carousel-card-title">{slides[(currentSlide + 1) % slides.length].title}</h3>
            <img
              src={slides[(currentSlide + 1) % slides.length].image}
              alt={slides[(currentSlide + 1) % slides.length].title}
              className="carousel-image"
            />
            <p className="carousel-card-caption">{slides[currentSlide].caption}</p>
          </div>
      </div>

      <button className="carousel-control next" onClick={nextSlide}>
        &#10095;
      </button>
    </div>
  );
};

Carousel.propTypes = {
  slides: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      image: PropTypes.string.isRequired,
      caption: PropTypes.string.isRequired,
    })
  ).isRequired,
};

export default Carousel;
