import React, {useState, useEffect} from 'react';
import Navbar from './Components/Navbar/Navbar';
import Footer from './Components/Footer/Footer';
import { useNavigate } from 'react-router-dom';
import {db, collection, getDocs} from './firebaseConfig';
import './Careers.css';

const Careers = () => {
  const [jobList, setJobList] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchJobs = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, 'jobs'));
        const jobs = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setJobList(jobs);
      } catch (error) {
        console.error('Error fetching jobs:', error);
      }
    };

    fetchJobs();
  }, []);

  return (
    <div>
      <Navbar />
      <div className="careers__background">
        <div className="careers__hero">
          <div className="careers__hero-content">
            <h1 className="careers__hero-title">Your Next Opportunity Awaits</h1>
            <p className="careers__hero-subtitle">
              Join our dynamic team and shape the future of logistics.
            </p>
          </div>
        </div>

        <div className="careers__container">
          
          <div className="careers__header">
            <h1>Current Openings</h1>
            <p>Explore exciting opportunities to grow with us!</p>
          </div>
          <ul className="careers__jobList">
            {jobList.map((job) => (
              <li key={job.id} className="careers__jobItem">
                <div className="careers__jobItem-textcontainer">
                  <h3 className="careers__jobItem-header">{job.jobTitle}</h3>
                  <p className="careers__jobItem-desc">{job.jobDesc}</p>
                </div>
                <button onClick={() => navigate(`/job/${job.id}`)} className="careers__button">View & Apply</button>
              </li>
            ))}
          </ul>
        </div>
      </div>
      <Footer/>
    </div>
  );
};

export default Careers;
