import React, { useState, useEffect } from "react";
import { db, doc, getDoc, updateDoc } from "./firebaseConfig";
import { useParams, useNavigate } from "react-router-dom";
import Navbar from "./Components/Navbar/Navbar";
import Footer from "./Components/Footer/Footer";
import { useAuth } from "./AuthContext";
import './AdminJob.css';

const EditJob = () => {

  const { user } = useAuth();
  const navigate = useNavigate();
  useEffect(() => {
    if (!user) {
      navigate("/admin");
    }
  }, [user, navigate]);
  const { id } = useParams(); // Job ID from URL
  
  const [formData, setFormData] = useState({
    jobTitle: "",
    jobDesc: "",
    responsibilities: [""],
    requirements: [""],
    preferredSkills: [""],
  });

  useEffect(() => {
    const fetchJob = async () => {
      try {
        const jobRef = doc(db, "jobs", id);
        const jobDoc = await getDoc(jobRef);
        if (jobDoc.exists()) {
          setFormData(jobDoc.data());
        } else {
          console.error("Job not found!");
          navigate("/admin"); // TODO: Add a popup
        }
      } catch (error) {
        console.error("Error fetching job:", error);
      }
    };

    fetchJob();
  }, [id, navigate]);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleArrayChange = (e, fieldName, index) => {
    const updatedArray = [...formData[fieldName]];
    updatedArray[index] = e.target.value;
    setFormData({
      ...formData,
      [fieldName]: updatedArray,
    });
  };

  const addArrayItem = (fieldName) => {
    setFormData({
      ...formData,
      [fieldName]: [...formData[fieldName], ""],
    });
  };

  const removeArrayItem = (fieldName, index) => {
    const updatedArray = formData[fieldName].filter((_, i) => i !== index);
    setFormData({
      ...formData,
      [fieldName]: updatedArray,
    });
  };

  const handleUpdate = async (e) => {
    e.preventDefault();

    try {
      const jobRef = doc(db, "jobs", id);
      await updateDoc(jobRef, formData);
      alert("Job successfully updated!");
      navigate("/admin");
    } catch (error) {
      console.error("Error updating job:", error);
    }
  };

  return (
    <div>
      <Navbar />
      <div className="admin-job__container">
        <h3 className="admin-job__title">Edit Job</h3>
        <form onSubmit={handleUpdate} className="admin-job__form">
          <div className="admin-job__input-group">
              Job Title:
              <input
                type="text"
                name="jobTitle"
                value={formData.jobTitle}
                onChange={handleChange}
                required
              />
          </div>

          <div className="admin-job__input-group">
              Job Description:
              <textarea
                name="jobDesc"
                value={formData.jobDesc}
                onChange={handleChange}
                required
              />
          </div>

          <div className="admin-job__input-group">
              Responsibilities:
              {formData.responsibilities.map((item, index) => (
                <div key={index} className="admin-job__array-item">
                  <input
                    type="text"
                    value={item}
                    onChange={(e) =>
                      handleArrayChange(e, "responsibilities", index)
                    }
                  />
                  <button
                    type="button"
                    onClick={() => removeArrayItem("responsibilities", index)}
                    className="admin-job__remove-button"
                  >
                    Remove
                  </button>
                </div>
              ))}
              <button
                type="button"
                onClick={() => addArrayItem("responsibilities")}
                className="admin-job__add-button"
              >
                Add Responsibility
              </button>
          </div>

          <div className="admin-job__input-group">
              Requirements:
              {formData.requirements.map((item, index) => (
                <div key={index} className="admin-job__array-item">
                  <input
                    type="text"
                    value={item}
                    onChange={(e) =>
                      handleArrayChange(e, "requirements", index)
                    }
                  />
                  <button
                    type="button"
                    onClick={() => removeArrayItem("requirements", index)}
                    className="admin-job__remove-button"
                  >
                    Remove
                  </button>
                </div>
              ))}
              <button
                type="button"
                onClick={() => addArrayItem("requirements")}
                className="admin-job__add-button"
              >
                Add Requirement
              </button>
          </div>

          <div className="admin-job__input-group">
              Preferred Skills:
              {formData.preferredSkills.map((item, index) => (
                <div key={index} className="admin-job__array-item">
                  <input
                    type="text"
                    value={item}
                    onChange={(e) =>
                      handleArrayChange(e, "preferredSkills", index)
                    }
                  />
                  <button
                    type="button"
                    onClick={() => removeArrayItem("preferredSkills", index)}
                    className="admin-job__remove-button"
                  >
                    Remove
                  </button>
                </div>
              ))}
              <button
                type="button"
                onClick={() => addArrayItem("preferredSkills")}
                className="admin-job__add-button"
              >
                Add Skill
              </button>
          </div>

          <button type="submit" className="admin-job__submit-button">
            Update Job
          </button>
        </form>
      </div>
      <Footer />
    </div>
  );
};

export default EditJob;