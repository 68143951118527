import React, { useState, useEffect } from "react";
import { db, getDocs, deleteDoc, doc, collection } from "./firebaseConfig";
import { useNavigate } from "react-router-dom";
import "./Admin.css"
import { ChevronDown, ChevronUp } from "react-feather";
const JobList = () => {
  const [jobs, setJobs] = useState([]);
  const [expandedJob, setExpandedJob] = useState(null); // Track expanded job
  const navigate = useNavigate();

  // Toggle job details visibility
  const toggleJobDetails = (jobId) => {
    setExpandedJob(expandedJob === jobId ? null : jobId);
  };

  const fetchJobs = async () => {
    try {
      const querySnapshot = await getDocs(collection(db, "jobs"));
      const jobsData = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setJobs(jobsData);
    } catch (error) {
      console.error("Error fetching jobs:", error);
    }
  };

  const handleDelete = async (jobId) => {
    try {
      await deleteDoc(doc(db, "jobs", jobId));
      alert("Job successfully deleted!");
      fetchJobs();
    } catch (error) {
      console.error("Error deleting job:", error);
    }
  };

  useEffect(() => {
    fetchJobs();
  }, []);

  return (
      <div className="admin__job-list">
        <h3 className="admin__job-list-header">Current Job Postings</h3>
        {jobs.map((job) => (
          <div key={job.id} className="admin__job-item">
            <div className="admin__job-item-header">
              <h1 className="admin__job-item-title">{job.jobTitle}</h1>
              <div className="admin__job-item-buttons">
                <button className="admin__button" onClick={() => navigate(`edit-job/${job.id}`)}>Edit Job</button>
                <button className="admin__button"onClick={() => handleDelete(job.id)}>Delete Job</button>
                <button
                  className="admin_job-item-dropdown"
                  onClick={() => toggleJobDetails(job.id)}
                >
                  {expandedJob === job.id ? <ChevronUp /> : <ChevronDown />}
                </button>
              </div>
            </div>
            {expandedJob === job.id && (
              <div className="admin__job__details">
                <strong>Job Description:</strong>
                <p>{job.jobDesc}</p>
                <strong>Responsibilities:</strong>
                <ul>
                  {job.responsibilities?.map((resp, index) => (
                    <li key={index}>{resp}</li>
                  ))}
                </ul>
                <strong>Requirements:</strong>
                <ul>
                  {job.requirements?.map((req, index) => (
                    <li key={index}>{req}</li>
                  ))}
                </ul>
                <strong>Preferred Skills:</strong>
                <ul>
                  {job.preferredSkills?.map((skill, index) => (
                    <li key={index}>{skill}</li>
                  ))}
                </ul>
              </div>
            )}
          </div>
        ))}
      </div>
  );
};

export default JobList;
