import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { ThemeProvider } from './ThemeContext';
import { AuthProvider } from "./AuthContext";
import './styles.css';

import Home from './Home';
import Transportation from './Transportation';
import Logistics from './Logistics';
import Contact from './Contact';
import Careers from './Careers';
import JobApplication from './JobApplication';
import ScrollToTop from './ScrollToTop';
import Admin from './Admin';
import EditJob from "./AdminEditJob";
import AddJob from "./AdminAddJob";
function App() {
  return (
    <ThemeProvider>
      <Router>
        <AuthProvider>
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/home" element={<Home />} />
          <Route path="/Transportation" element={<Transportation />} />
          <Route path="/Careers" element={<Careers />} />
          <Route path="/job/:id" element={<JobApplication />} />
          <Route path="/Logistics" element={<Logistics />} />
          <Route path="/Contact" element={<Contact />} />
          <Route path="/Admin" element={<Admin />} />
          <Route path="/Admin/edit-job/:id" element={<EditJob />} />
          <Route path="/Admin/add-job" element={<AddJob />} />
        </Routes>
        </AuthProvider>
      </Router>
    </ThemeProvider>
  );
}

export default App;
