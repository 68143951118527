import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "./AuthContext";
import JobList from "./AdminJobList";
import Navbar from "./Components/Navbar/Navbar";
import Footer from "./Components/Footer/Footer";
import { getFirestore, doc, getDoc } from "firebase/firestore";
import './Admin.css';

const Admin = () => {
  const { user, login, logout } = useAuth();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);

  

  // Check authentication status on page load
  useEffect(() => {
    if (!user) {
      setLoading(false); // Stop loading if user isn't authenticated
    } else {
      setLoading(false); // Stop loading when authenticated
    }
  }, [user]);

  useEffect(() => {
    const checkUserRole = async (user) => {
      const db = getFirestore();
      const userRef = doc(db, "admins", user.uid);
      const docSnap = await getDoc(userRef);
      console.log(user.uid);
      if (docSnap.exists() !== true) {
        console.log("Invalid Admin Email! Returning Home... 2");
        logout()
      }
    };
    if (user) {
      checkUserRole(user);
    }
  }, [user, logout]);

  // If still loading or not authenticated, show loading state or login prompt
  if (loading) {
    return <div>Loading...</div>;
  }

  // If user is not authenticated, show the login prompt or redirect
  if (!user) {
    return (
      <div>
        <Navbar />
        <div className="admin__container">
          <h1 className="admin__header">Admin Panel</h1>
          <button className="admin__button" onClick={login}>Login with Google</button>
        </div>
        <Footer/>
      </div>
    );
  }

  return (
    <div>
      <Navbar />
      <div className="admin__container">
        <h1 className="admin__header">Admin Panel</h1>
        <JobList />
        <div className="admin__button-div">
        <button
          id="admin-add-button"
          className="admin__button"
          onClick={() => navigate("add-job")}
        >
          Add New Job
        </button>
        <button id="admin-logout-button" className="admin__button" onClick={logout}>Logout</button>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Admin;
